import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

class AwardsSlider {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.sliderEl = this.root.querySelector('[data-award-slider-swiper]');
        this.prevEl = this.root.querySelector('[data-award-slider-prev]');
        this.nextEl = this.root.querySelector('[data-award-slider-next]');

        this.slider = null;

        return true;
    }

    init() {
        this.initSlider();
    }

    initSlider() {
        this.slider = new Swiper(this.sliderEl, {
            modules: [Navigation],
            slidesPerView: 1,
            navigation: {
                prevEl: this.prevEl,
                nextEl: this.nextEl,
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                    spaceBetween: 32,
                },
                992: {
                    slidesPerView: 4,
                    spaceBetween: 32,
                },
            },
        });
    }
}

function initAwardsSlider() {
    const sections = document.querySelectorAll('[data-award-slider]');
    if (!sections) return;

    sections.forEach(item => new AwardsSlider(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initAwardsSlider();
} else {
    document.addEventListener('DOMContentLoaded', initAwardsSlider);
}
